import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from "@material-ui/core/styles"

import ColumnIcon from './ColumnIcon'

const useStyles = makeStyles(theme => ({
	circle: {
		width: theme.spacing(16),
		height: theme.spacing(16),
		display: 'flex',
		boxShadow: `0 0 0 10px hsl(0deg 0% 93%)`,
		alignItems: "center",
		background: theme.palette.common.white,
		borderRadius: "50%",
		flexDirection: "column",
		justifyContent: "center"
	},
	columnTitle: {
		textAlign: "center",
		fontFamily: "Ubuntu, sans-serif",
	},
	columnText: {
		textAlign: 'center'
	}
}))

const ContactColumn = ({
	contactPageColumnIcon,
	contactPageColumnCaption,
	contactPageColumnDescription,
}) => {
	const classes = useStyles()

	return (
		<Grid item md={3} xs={12}>
			<Box display="flex" flexDirection="column" alignItems="center" mb={0.5} mt={2}>
				<Box className={classes.circle} mb={3}>
					<ColumnIcon icon={contactPageColumnIcon} />
				</Box>
				<Typography
					variant='h5'
					gutterBottom
					className={classes.columnTitle}
				>{contactPageColumnCaption}</Typography>
				<Typography
					className={classes.columnText}
					component="div"
					dangerouslySetInnerHTML={{
						__html: contactPageColumnDescription
					}}
				/>
			</Box>
		</Grid>
	);
}

export default ContactColumn
