import React from 'react';
import Layout from '../components/layout';
import ContactView from '../views/contactView'

const ContactPage = () => (
	<Layout isBlack>
		<ContactView />
	</Layout>
)

export default ContactPage;