import React, { useState } from 'react';
import * as Yup from "yup"
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Alert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import MuiTextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Field, Form, Formik } from "formik"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"

import encode from '../../helpers/encode';
import Title from '../../components/title';
import Button from '../../components/button';
import ContactColumn from '../../components/contactColumn';

const INITIAL_VALUES = {
	name: "",
	email: "",
	message: "",
}

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Pole jest za krótkie")
		.max(50, "Pole jest za długie")
		.required("Wymagane"),
	email: Yup.string().email("Email jest nieprawidłowy").required("Wymagane"),
	message: Yup.string()
		.min(2, "Pole jest za krótkie")
		.max(300, "Pole jest za długie")
		.required("Wymagane"),
})

const TextField = withStyles(theme => ({
	root: {
		color: theme.palette.common.black,
		"&:hover": {
			backgroundColor: theme.palette.common.white,
		},
		"& .MuiFormLabel-root": {
			color: theme.palette.text.secondary,
		},
		"& .MuiFilledInput-root": {
			borderRadius: 0,
			backgroundColor: theme.palette.common.white,
		}
	}
}))(MuiTextField)

const useStyles = makeStyles(theme => ({
	grid: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(8),
		alignItems: 'flex-start',
		justifyContent: 'center'
	},

	smallTitle: {
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(3),
			fontSize: '2.5rem',
		}
	},

	form: {
		backgroundColor: theme.palette.error.main
	},
	textCenter: {
		textAlign: "center"
	},
	formField: {
		color: theme.palette.grey[500],
		backgroundColor: theme.palette.common.white,
		marginBottom: theme.spacing(4)
	},
	whiteTitle: {
		color: theme.palette.common.white,
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(3),
			fontSize: '2.5rem',
		}
	},
	white: {
		color: theme.palette.common.white,
	},
	formHelper: {
		color: `${theme.palette.common.white} !important`,
		padding: `0 ${theme.spacing(0.5)}px`,
		margin: 0,
		fontSize: '14px',
		backgroundColor: theme.palette.error.main
	}
}))

const ContactPage = () => {
	const classes = useStyles()

	const [open, setOpen] = useState(false)

	const handleClose = () => setOpen(false)

	const { datoCmsPageContact = {} } = useStaticQuery(graphql`
		query QueryViewContact {
			datoCmsPageContact {
				contactPageDescription
				contactPageFormDescription
				contactPageFormTitle
				contactPageTitle
				contactPageColumns {
					contactPageColumnCaption
					contactPageColumnDescription
					contactPageColumnIcon
					id
				}
			}
		}
  `)

	const {
		contactPageTitle,
		contactPageColumns = [],
		contactPageFormTitle,
		contactPageDescription,
		contactPageFormDescription,
	} = datoCmsPageContact



	const handleSubmit = ({ name, email, message }, actions) => {
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": "Pasowanie siodeł formularz kontaktowy - Home",
				name,
				email,
				message
			}),
		})
			.then(response => {
				if (response.ok) {
					setOpen(true)
					actions.resetForm()
				}
			})
			.catch(error => {
				console.log("Error", { error })
			})
			.finally(() => actions.setSubmitting(false))
	}

	return (
		<React.Fragment>
			<Container maxWidth="md">
				<Box mt={20} display="flex" justifyContent="center">
					<Title className={classes.smallTitle} variant='h3'>{contactPageTitle}</Title>
				</Box>
				<Typography gutterBottom className={classes.textCenter}>{contactPageDescription}</Typography>
				<Grid container spacing={4} className={classes.grid}>
					{contactPageColumns.map(({ id, ...props }) => <ContactColumn key={id} {...props} />)}
				</Grid>
			</Container>
			<Box className={classes.form} py={10}>
				<Container maxWidth="md">
					<Formik
						onSubmit={handleSubmit}
						initialValues={INITIAL_VALUES}
						validationSchema={ContactSchema}
					>
						{props => {
							return (
								<Form
									name="Pasowanie siodeł formularz kontaktowy - Home"
									data-netlify={true}
									netlify-honeypot="bot-field"
								>
									<div style={{ display: "none" }}>
										<label>
											Don’t fill this out: <input name="bot-field" />
										</label>
									</div>

									<Typography
										variant='h3'
										className={classes.whiteTitle}
										gutterBottom
									>{contactPageFormTitle}</Typography>

									<Field name="name" autoComplete="off">
										{({ field, form }) => (
											<TextField
												{...field}
												fullWidth
												variant="filled"
												id="name"
												label="Imię"
												color="secondary"
												error={Boolean(form.errors[field.name])}
												helperText={form.errors[field.name]}
												className={classes.formField}
												FormHelperTextProps={{
													classes: { root: classes.formHelper }
												}}
											/>
										)}
									</Field>

									<Field name="email" autoComplete="off">
										{({ field, form }) => (
											<TextField
												{...field}
												fullWidth
												variant="filled"
												label="Email"
												color="secondary"
												error={Boolean(form.errors[field.name])}
												helperText={form.errors[field.name]}
												className={classes.formField}
												FormHelperTextProps={{
													classes: { root: classes.formHelper }
												}}
											/>
										)}
									</Field>

									<Field name="message" autoComplete="off">
										{({ field, form }) => (
											<TextField
												{...field}
												fullWidth
												multiline
												minRows={7}
												variant="filled"
												label="Treść wiadomości"
												color="secondary"
												error={Boolean(form.errors[field.name])}
												helperText={form.errors[field.name]}
												className={classes.formField}
												FormHelperTextProps={{
													classes: { root: classes.formHelper }
												}}
											/>
										)}
									</Field>

									<Typography variant='body2' className={classes.white}>{contactPageFormDescription}</Typography>

									<Box display="flex" justifyContent="flex-end" mt={6}>
										<Button
											type="submit"
											bgColor="#000"
											size="medium"
											disabled={
												!(props.isValid && props.dirty) || props.isSubmitting
											}
											className={classes.submitButton}
											startIcon={
												props.isSubmitting && (
													<CircularProgress color="inherit" size={20} />
												)
											}>Wyślij wiadomość</Button>
									</Box>

									{/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
								</Form>
							)
						}}
					</Formik>

					<Snackbar
						open={open}
						autoHideDuration={4000}
						onClose={handleClose}
					>
						<Alert
							severity="success"
							className={classes.alert}
							action={null}
						>
							Wiadomość została wysłana, dziękujemy.
						</Alert>
					</Snackbar>

				</Container>
			</Box>
		</React.Fragment>
	)
}

export default ContactPage;